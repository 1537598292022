import React from "react";
import {
  FooterContainer,
  FooterH2,
  FooterLink,
  FooterWrapper,
} from "./FooterElements";
import "./../../App.css";
import { Link } from 'react-scroll';
import { useTranslation } from "react-i18next";

const Footer = () => {

  const { t } = useTranslation('common');

  return (
    <FooterContainer>
      <FooterWrapper className="container py-5">
        <div className="row gx-5 px-4 gy-4">
          <div className="col-lg-3 col-sm-6">
            <FooterH2>{t("footer.aboutUs")}</FooterH2>
            <FooterLink>
              <Link to="about" smooth={true} duration={200}>{t("footer.ourStory")}</Link>
            </FooterLink>
            <FooterLink>
              <Link to="whoWeAre" smooth={true} duration={200}>{t("footer.whoWeAre")}</Link>
            </FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>{t("footer.contactUs")}</FooterH2>
            <FooterLink href="#">
             <Link to="contact" smooth={true} duration={200}>{t("footer.contact")}</Link> 
            </FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>{t("footer.customers")}</FooterH2>
            <FooterLink href="#">
            <Link to="customers" smooth={true} duration={200}>{t("footer.ourCollaborations")}</Link> 
            </FooterLink>
            <FooterLink href="#">
              <Link to="customers" smooth={true} duration={200}>{t("footer.ourProjects")}</Link> 
              </FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>{t("footer.followUs")}</FooterH2>
            <FooterLink href="https://www.youtube.com/@chihuasdevs8632">Youtube</FooterLink>
            <FooterLink href="https://www.facebook.com/chihuasdevs/">Facebook</FooterLink>
            <FooterLink href="https://www.linkedin.com/company/39262984">LinkedIn</FooterLink>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5 ">
            <h1 className="logo-text gray-text center-align">- Chihuasdevs -</h1>
          </div>
        </div>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;

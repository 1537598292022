import React, { useState } from "react";
import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";

import Image1 from "../images/design-notes.svg";
import Image2 from "../images/space.svg";
import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import { CollaborationSection, Client } from '../components/collaborationSection';
import ContactForm from "../components/contactForm";




import AforeMovilLogo from "../images/aforemovil.png";
import InterceramicLogo from "../images/interceramic.png";
import KeyaErpLogo from "../images/keya.png";
import OpticaDiezLogo from "../images/optica-diez.jpeg";
import OpticaLeoLogo from "../images/opticaleo.jpeg";
import SegurnostiLogo from "../images/segurnosti.jpeg";
import LaboraltyLogo from "../images/laboralty.png";
import ClaroMusicaLogo from "../images/claro-musica.png";
import FumiplagasLogo from "../images/fumiplagas.jpeg";
import DishLogo from "../images/dish.png";
import MexiRoomiesLogo from "../images/mexiroomies.png";
import AldamaEatsLogo from "../images/aldamaeats.png";
import PictoboardLogo from "../images/pictoboard.png";


export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('common');

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection
        image={Image1}
        id="about"
        subtitle={t('about.subtitle')}
        title={t("about.title")}
        text={t("about.text")}
        btnText="Start today"
      />
      <InfoSectionLight
        image={Image2}
        id="whoWeAre"
        subtitle={t("whoWeAre.subtitle")}
        title={t("whoWeAre.title")}
        text={
          t("whoWeAre.text")
        }
        btnText="Explore"
      />
      <Services />

      <CollaborationSection id="customers" title={t("customers.title")}>
        <Client logo={AforeMovilLogo} text="AFORE MÓVIL" />
        <Client logo={InterceramicLogo} text="INTERCERAMIC" />
        <Client logo={OpticaDiezLogo} text="ÓPTICA DÍEZ" />
        <Client logo={OpticaLeoLogo} text="ÓPTICA LEO" />
        <Client logo={KeyaErpLogo} text="KEYA ERP" />
        <Client logo={SegurnostiLogo} text="SEGURNOSTI" />
        <Client logo={LaboraltyLogo} text="LABORALTY" />
        <Client logo={ClaroMusicaLogo} text="CLARO MÚSICA" />
        <Client logo={FumiplagasLogo} text="FUMIPLAGAS" />
        <Client logo={DishLogo} text="DISH NETWORK" />
      </CollaborationSection>

      <CollaborationSection title={t("ourProjects.title")}>
        <Client logo={AldamaEatsLogo} text="ALDAMAEATS" />
        <Client logo={PictoboardLogo} text="PICTOBOARD" />
        <Client logo={MexiRoomiesLogo} text="MEXIROOMIES" />
      </CollaborationSection>

      <ContactForm id="contact" title={t("contactUs.title")}/>

      <Footer />
    </>
  );
};

// CollaborationSection/CollaborationElements.js
import styled from 'styled-components';

export const SectionTitle = styled.h1`
  font-weight: 900;
  text-align: center;
  margin-bottom: 2rem;
`;

export const CollaborationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CollaborationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%; /* Ajustamos el ancho máximo para evitar desbordamientos */
`;

export const ClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: calc(33.33% - 20px); /* Ancho dinámico para cada cliente */
  max-width: 200px; /* Ancho máximo para cada cliente */
`;

export const LogoImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

export const Text = styled.p`
  text-align: center;
`;

import React, { useState } from 'react';
import { ContactContainer, ContactTitle, Form, FormLabel, FormInput, FormTextarea, FormButton, FormLoadingMessage, FormSuccessMessage, FormErrorMessage } from './ContactFormElements';
import { useTranslation } from "react-i18next";

const ContactForm = ({ id, title }) => {
    const { t } = useTranslation('common');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({}); 

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        setLoading(true); 
        setSuccess(false);
        setErrors({});

        try {
            const { name, email, message } = formData;
            const errors = {}; // Objeto para almacenar los errores de validación

            // Validar que los tres campos estén llenos
            if (!name) {
                errors.name = t("contactUs.mandatoryName");
            }
            if (!email) {
                errors.email = t("contactUs.mandatoryEmail");
            }
            if (!message) {
                errors.message = t("contactUs.mandatoryMessage");
            }

            if (Object.keys(errors).length > 0) {
                // Si hay errores de validación, actualizar el estado y detener el envío del formulario
                setErrors(errors);
                setLoading(false);
                setSuccess(false);
                return;
            }

            const emailData = {
                to: ['noe.miranda.f@gmail.com'],
                cc: [],
                subject: `Chihuasdevs contacto ${email} - ${name}`,
                text: `${message}`
            };
            const response = await fetch('https://www.chihuasdevs.com:3005/api/smtpmailer/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJpZF9kZV91c3VhcmlvX2ZpY3RpY2lvIiwiaWF0IjoxNzExMTg0NzA0LCJleHAiOjQ4NjY5NDQ3MDR9.cZb3TFPW5PWwceX5btFdJDopxsWzO6jiDORPf3IHqu4'
                },
                body: JSON.stringify(emailData)
            });
            if (response.ok) {
                setSuccess(true);
            } else {
                setError(true); 
            }
        } catch (error) {
            setError(true); 
        } finally {
            setLoading(false); // Desactivar el indicador de carga
        }
    };

    return (
        <ContactContainer id={id}>
            <ContactTitle>{title}</ContactTitle>
            <Form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '5px' }}>
                <FormLabel htmlFor="name">{t("contactUs.name")}</FormLabel>
                <FormInput
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                readOnly={loading || success}
                />
                {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
                </div>

                <div style={{ marginBottom: '10px' }}>
                    
                <FormLabel htmlFor="email">{t("contactUs.email")}</FormLabel>
                <FormInput
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                readOnly={loading || success}
                />
                {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                </div>

                <div style={{ marginBottom: '10px' }}>
                <FormLabel htmlFor="message">{t("contactUs.message")}</FormLabel>
                <FormTextarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                readOnly={loading || success}
                />
                {errors.message && <span style={{ color: 'red' }}>{errors.message}</span>}
                </div>

                {!loading && !success && (
                <FormButton type="submit">{t("contactUs.submit")}</FormButton>
                )}
                {loading && <FormLoadingMessage>{t("contactUs.sending")}</FormLoadingMessage>}
                {success && <FormSuccessMessage>{t("contactUs.sent")}</FormSuccessMessage>}
                {error && <FormErrorMessage>{t("contactUs.error")}</FormErrorMessage>}
            </Form>
        </ContactContainer>
    );
};

export default ContactForm;

import React from "react";
import { CollaborationContainer, CollaborationWrapper, ClientContainer, LogoImg, Text, SectionTitle } from './CollaborationElements';

export const CollaborationSection = ({ id,title, children }) => {
    return (
        <div>
          <SectionTitle id={id}>{title}</SectionTitle>
          <CollaborationContainer>
            <CollaborationWrapper>{children}</CollaborationWrapper>
          </CollaborationContainer>
        </div>
      );
  };


export const Client = ({ logo, text }) => {
    return (
      <ClientContainer>
        <LogoImg src={logo} alt="Company Logo" />
        <Text>{text}</Text>
      </ClientContainer>
    );
  };
  
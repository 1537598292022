import styled from 'styled-components';

export const ContactContainer = styled.div`
    background: #010606; /* Fondo oscuro */
    padding: 100px 0; /* Agregar padding alrededor del formulario */
`;

export const ContactTitle = styled.h1`
    color: #fff; /* Texto blanco */
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 64px;
`;

export const Form = styled.form`
    width: 100%;
    max-width: 600px; /* Ancho máximo del formulario */
    margin: 0 auto;
`;

export const FormLabel = styled.label`
    color: #fff; /* Texto blanco */
    font-size: 1.2rem;
    margin-bottom: 8px;
`;

export const FormInput = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
`;

export const FormTextarea = styled.textarea`
    width: 100%;
    height: 150px; /* Altura del área de texto */
    padding: 10px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
`;

export const FormButton = styled.button`
    padding: 10px 20px;
    background: #B05FFD; /* Color de fondo */
    color: #fff; /* Texto blanco */
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #8A2BE2; /* Cambio de color al pasar el ratón */
    }
`;


export const FormLoadingMessage = styled.div`
    color: #fff;
    font-size: 1rem;
    text-align: center;
    margin-top: 16px;
`;

export const FormSuccessMessage = styled.div`
    color: #fff;
    font-size: 1rem;
    text-align: center;
    margin-top: 16px;
`;

export const FormErrorMessage = styled.div`
    color: #ff0000;
    font-size: 1rem;
    text-align: center;
    margin-top: 16px;
`;
import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarRoute, SideBtnWrap, SidebarWrapper, SidebarMenu, SidebarLink, LanguageSelect} from './SidebarElements'
import { useTranslation } from 'react-i18next';
export const Sidebar = ({isOpen, toggle}) => {

    const { t, i18n } = useTranslation('common');

    const handleLanguageChange = (e) => {
        e.stopPropagation(); // Detener la propagación del evento
        i18n.changeLanguage(e.target.value);
    };

    const handleLanguageClick = (e) => {
        e.stopPropagation(); // Detener la propagación del evento de clic
    };

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>
                        {t("navbar.about")}
                    </SidebarLink>
                    <SidebarLink to="whoWeAre" onClick={toggle}>
                    {t("navbar.whoWeAre")}
                    </SidebarLink>
                    <SidebarLink to="services" onClick={toggle}>
                    {t("navbar.services")}
                    </SidebarLink>
                    <SidebarLink to="customers" onClick={toggle}>
                    {t("navbar.customers")}
                    </SidebarLink>
                    <SidebarLink to="contact" onClick={toggle}>
                    {t("navbar.contact")}
                    </SidebarLink>

                    <LanguageSelect onClick={handleLanguageClick}> {/* Manejador de eventos para el clic del selector */}
                        <select onChange={handleLanguageChange}>
                            <option value="en">English</option>
                            <option value="es">Español</option>
                        </select>
                    </LanguageSelect>

                </SidebarMenu>
                {/*<SideBtnWrap>
                    <SidebarRoute to="/signin" onClick={toggle}>
                        Sign In
                    </SidebarRoute>
    </SideBtnWrap>*/}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

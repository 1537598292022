import React from "react";
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from "./ServicesElements";
import Icon1 from "../../images/space.svg"
import Icon2 from "../../images/online.svg"
import Icon3 from "../../images/real-time.svg"
import { useTranslation } from "react-i18next";


const Services = () => {

  const { t } = useTranslation('common');

  return (
    <ServicesContainer id="services">
      <ServicesH1>{t("services.title")}</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2> {t("services.subtitle")}</ServicesH2>
          <ServicesP>

          <div dangerouslySetInnerHTML={{ __html: t("services.text") }} />
        
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;

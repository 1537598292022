import React, { useState, useEffect } from "react";
import ChihuasDevsLogo from "../../images/chihuasdevs-logo.png";
import { useTranslation } from 'react-i18next';
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  LogoImg,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  LanguageSelect,
  DropdownMenu,
  DropdownItem,
} from "./NavbarElements";
import "./../../App.css";

export const Navbar = ({ toggle }) => {
  const { t, i18n } = useTranslation('common');

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const menuItems = [
    { label: t("navbar.about"), to: "about" },
    { label: t("navbar.whoWeAre"), to: "whoWeAre" },
    { label: t("navbar.services"), to: "services" },
    { label: t("navbar.customers"), to: "customers" },
    { label: t("navbar.contact"), to: "contact" },
    // Agrega más elementos según sea necesario
  ];

  const visibleItems = menuItems.slice(0, 3);
  const hiddenItems = menuItems.slice(3); 


  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
      window.addEventListener('scroll', changeNav);      
  }, [])


  
  return (
    <>
    <Nav scrollNav={scrollNav}>
      <NavbarContainer>
      <NavLogo to="/">
      <LogoImg src={ChihuasDevsLogo} />
        <h1 className="logo-text">Chihuasdevs</h1>
      </NavLogo>
      <MobileIcon onClick={toggle}>
        <FaBars />
      </MobileIcon>
        <NavMenu>
          {visibleItems.map((item, index) => (
            <NavItem key={index}>
              <NavLinks to={item.to}>{item.label}</NavLinks>
            </NavItem>
    
          ))}
            <LanguageSelect>
            <select onChange={(e) => i18n.changeLanguage(e.target.value)}>
              <option value="en">English</option>
              <option value="es">Español</option>
            </select>
          </LanguageSelect>
          {hiddenItems.length > 0 && (
             <NavItem>
             <NavLinks onClick={toggleDropdown}>
               {t("navbar.more")}
             </NavLinks>
             {isDropdownOpen && (
               <DropdownMenu>
                 {hiddenItems.map((item, index) => (
                   <DropdownItem key={index}>
                     <NavLinks to={item.to}>{item.label}</NavLinks>
                   </DropdownItem>
                 ))}
               </DropdownMenu>
             )}
           </NavItem>
          )}
        </NavMenu>
      
      </NavbarContainer>
    </Nav>
  </>
);
};
